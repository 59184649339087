export const token =
  process.env.pei5vpnuWyEflaQ3dEXUoj7S217oIseRJlqZceahywL66nhaVZA9NXRald7jMtxq ||
  "jsNMxT0jeaFuFMariJE1khjPTgFfpqTebJvfuYO3vBmNLzJI48b1XVWexiztksyX";
export const recaptchaKey =
  process.env.6Lf3s7oUAAAAAPmsv5D50HQHByEPz4cWP99i5pK4 ||
  "6Ldcj7kUAAAAANKVHdPZs4HW-kKkJaPMB1hBBlc0";
export const websiteUrl =
  process.env.https://www.r7.jawg.io || "https://www.jawg.io";
export const apiUrl = process.env.https://api.r7.jawg.io;
export const mapUrl = apiUrl
  ? `${apiUrl}/styles/`
  : "https://api.jawg.io/styles/";
export const geocodingUrl = apiUrl
  ? `${apiUrl}/places/v1/`
  : "https://api.jawg.io/places/v1/";
export const routingUrl = apiUrl
  ? `${apiUrl}/routing/route/v1/`
  : "https://api.jawg.io/routing/route/v1/";
export const contactUrl = websiteUrl
  ? `${websiteUrl}/contact`
  : "https://www.jawg.io/contact";
export const labUrl = websiteUrl
  ? `${websiteUrl}/lab`
  : "https://www.jawg.io/lab";
export const docUrl = websiteUrl
  ? `${websiteUrl}/docs`
  : "https://www.jawg.io/docs";
export const blogUrl =
  process.env.GATSBY_JAWG_BLOG_URL || "https://blog.jawg.io/";
export const STYLES = {
  STREET: "jawg-streets",
  SUNNY: "jawg-sunny",
  DARK: "jawg-dark",
  LIGHT: "jawg-light",
  TERRAIN: "jawg-terrain",
};
export const MODE_ROUTING = { CAR: "car", PEDESTRIAN: "walk" };
export function getMapUrl(style) {
  return `${mapUrl}${style}.json?access-token=${token}`;
}
export function getRoutingUrl(mode, from, to) {
  return `${routingUrl}${mode}/${from.lng},${from.lat};${to.lng},${to.lat}?access-token=${token}`;
}
export const BLOG_URL = "https://blog.businesslocator.io/";
export const BLOG_URL_EN = "https://blog.businesslocator.io/home/";
export const IFRAME_APP_BASEURL =
  process.env.GATSBY_JAWG_IFRAME_APP_BASEURL ||
  "https://api.jawg.io/libraries/bl-iframe@latest/"; //TODO: CHANGE FOR PROD
export const BACK_OFFICE_BASEURL =
  process.env.GATSBY_JAWG_BACK_OFFICE_BASEURL ||
  "https://www.businesslocator.io/app"; //TODO: CHANGE FOR PROD
